<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "BusinessRuleActions.EditBusinessRuleAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons @methodSave="onSubmit('btn-save')" :isNewRecord="false" />
    </div>
  </div>
  <form class="form-businessruleactionsendnotif-edit pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-4">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "BusinessRuleActions.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          @keyup="nameKeyup()"
          id="Name"
          v-model="businessRuleActionData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-4">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "BusinessRuleActions.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control text-uppercase"
          id="FormulaName"
          disabled
          :value="businessRuleActionData.formulaName"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col col-md-4">
        <label for="BusinessActionType" class="form-label"
          >{{
            $t(
              "BusinessRuleActions.BusinessActionType",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          disabled
          id="BusinessActionType"
          v-model="businessRuleActionType"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-12 mt-3">
        <label for="Description" class="form-label">{{
          $t(
            "BusinessRuleActions.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="businessRuleActionData.description"
          :spellcheck="this.$isTextSpellCheck"
          rows="2"
        ></textarea>
      </div>
      <div class="col col-md-12 mt-3">
        <label for="ResponseText" class="form-label">{{
          $t(
            "BusinessRuleActions.ResponseText",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="ResponseText"
          class="form-control"
          id="ResponseText"
          v-model="businessRuleActionData.responseText"
          :spellcheck="this.$isTextSpellCheck"
          rows="1"
        ></textarea>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div style="display: none" class="form-check isForcingSave">
        <label for="isForcingSave" class="form-label">{{
          $t(
            "BusinessRuleActions.IsForcingSave",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="isForcingSave"
          v-model="businessRuleActionData.isForcingSave"
        />
      </div>
      <div class="form-check isRunOnceForEachRecord">
        <label for="IsRunOnceForEachRecord" class="form-label">{{
          $t(
            "BusinessRuleActions.IsRunOnceForEachRecord",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsRunOnceForEachRecord"
          v-model="businessRuleActionData.isRunOnceForEachRecord"
        />
      </div>
      <div class="form-check">
        <label for="IsSynchAction" class="form-label">{{
          $t(
            "BusinessRuleActions.IsSynchAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsSynchAction"
          v-model="businessRuleActionData.isSynchAction"
        />
      </div>
      <div class="form-check">
        <label for="IsResponseReturnToClient" class="form-label">{{
          $t(
            "BusinessRuleActions.IsResponseReturnToClient",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsResponseReturnToClient"
          v-model="businessRuleActionData.isResponseReturnToClient"
        />
      </div>
      <div class="form-check httpRequestAdress" style="display: none">
        <label for="DownloadResponseAsDocument" class="form-label">{{
          $t(
            "BusinessRuleActions.DownloadResponseAsDocument",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="downloadResponseAsDocument"
          v-model="businessRuleActionData.DownloadResponseAsDocument"
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BusinessRuleActions.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="businessRuleActionData.isActive"
        />
      </div>
      <div
        style="display: none"
        class="form-check isNotSendEmailIfThereIsNoRecord"
      >
        <label for="isNotSendEmailIfThereIsNoRecord" class="form-label">{{
          $t(
            "BusinessRuleActions.IsNotSendEmailIfThereIsNoRecord",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsNotSendEmailIfThereIsNoRecord"
          v-model="businessRuleActionData.isNotSendEmailIfThereIsNoRecord"
        />
      </div>
      <div style="display: none" class="form-check isRunSeparatelyForEachUser">
        <label for="isRunSeparatelyForEachUser" class="form-label">{{
          $t(
            "BusinessRuleActions.IsRunSeparatelyForEachUser",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsRunSeparatelyForEachUser"
          v-model="businessRuleActionData.isRunSeparatelyForEachUser"
        />
      </div>
    </div>
    <div class="row row-cols-12 sendNotification">
      <div class="col col-md-12">
        <label for="NotificationType" class="form-label required">{{
          $t(
            "BusinessRuleActions.NotificationType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :allowEmpty="false"
          :selectedData="selectedNotificationType"
          :data="notificationTypeData"
          @onChange="onChangeForNotificationType"
        />
      </div>
      <div class="row row-cols-12 mt-3 emailNotTab">
        <div class="col col-md-6">
          <label for="NotificationSettings" class="form-label required">{{
            $t(
              "BusinessRuleActions.NotificationSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :selectedData="selectedNotifierSetting"
            :resetSelect="selectsReset"
            :isParameters="true"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryNotifierSettings?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForNotifierSettings"
          />
        </div>
        <div class="col col-md-6">
          <label for="SelectMessageTemplate" class="form-label required">{{
            $t(
              "BusinessRuleActions.SelectMessageTemplate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :selectedData="selectedMessageTemplate"
            :resetSelect="selectsReset"
            :isParameters="true"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryMessageTemplates?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForMessageTemplate"
          />
        </div>
      </div>
      <div style="display: none" class="row row-cols-12 mt-3 smsNotTab">
        <div class="col col-md-6">
          <label for="NotificationSettings" class="form-label required">{{
            $t(
              "BusinessRuleActions.NotificationSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :selectedData="selectedNotifierSetting"
            :resetSelect="selectsReset"
            :isParameters="true"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryNotifierSettings?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForNotifierSettings"
          />
        </div>
        <div class="col col-md-6">
          <label for="SelectMessageTemplate" class="form-label">{{
            $t(
              "BusinessRuleActions.SelectMessageTemplate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :selectedData="selectedMessageTemplate"
            :resetSelect="selectsReset"
            :isParameters="true"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryMessageTemplates?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForMessageTemplate"
          />
        </div>
      </div>
      <div style="display: none" class="row row-cols-12 mt-3 systemNotTab">
        <div class="col col-md-6">
          <label for="NotificationSettings" class="form-label required">{{
            $t(
              "BusinessRuleActions.NotificationSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :selectedData="selectedNotifierSetting"
            :resetSelect="selectsReset"
            :isParameters="true"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryNotifierSettings?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForNotifierSettings"
          />
        </div>
        <div class="col col-md-6">
          <label for="SelectMessageTemplate" class="form-label">{{
            $t(
              "BusinessRuleActions.SelectMessageTemplate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            type="SelectWithRemoteDataAndInternalSearch"
            :allowEmpty="false"
            :resetSelect="selectsReset"
            :selectedData="selectedMessageTemplate"
            :isParameters="true"
            :isGatewayRequest="true"
            :requestUrl="
              String.format(
                '/Lcdp-SummaryMessageTemplates?type={0}',
                this.notificationType
              )
            "
            @onChange="onChangeForMessageTemplate"
          />
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "Fields.CustomSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>

        <div class="col col-md-12 mt-3 sendByEmail">
          <div class="form-check">
            <label
              for="SendByEmailOfUserWhoUpdatesRegistration"
              class="form-label"
              >{{
                $t(
                  "BusinessRuleActions.SendByEmailOfUserWhoUpdatesRegistration",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              id="SendByEmailOfUserWhoUpdatesRegistration"
              v-model="businessRuleActionData.isSendWithCurrentUserMail"
            />
          </div>
        </div>

        <div class="row row-cols-12 sendNotification email">
          <div class="col col-md-6">
            <label for="Subject" class="form-label required"
              >{{
                $t(
                  "BusinessRuleActions.Subject",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="Subject"
              v-model="businessRuleActionData.subject"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-6 notificationPlugin">
            <label for="notificationPlugin" class="form-label">{{
              $t(
                "BusinessRuleActions.SelectTemplateForNotificationPlugin",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :selectedData="selectedNotificationPlugin"
              :isParameters="true"
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryFormTemplates?coId={0}',
                  this.$route.params.customObjectId
                )
              "
              @onChange="onChangeFormTemplatesForBusinessRule"
            />
          </div>
          <div class="col col-md-12 mt-2 attachment">
            <label for="attachment" class="form-label">{{
              $t(
                "BusinessRuleActions.SelectAttachment",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :isMultiple="true"
              :selectedData="selectedAttachments"
              @onMultipleSelectChange="onChangeForFieldsByTypeFile"
              @onMultipleSelectChangeReturnValues="
                onChangeForFieldsByTypeFileNames
              "
              :isParameters="true"
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryGetRelatedAttachmentFieldsByCustomObject?parentId={0}',
                  this.$route.params.customObjectId
                )
              "
            />
          </div>
        </div>

        <ul class="nav nav-tabs mb-2 mt-2 tabs sendNotification" role="tablist">
          <li class="nav-item toWhoTab" role="presentation">
            <button
              class="nav-link active"
              id="pills-to-who-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-to-who"
              type="button"
              role="tab"
              aria-selected="true"
            >
              {{
                $t(
                  "BusinessRuleActions.ToWho",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
          <li class="nav-item ccTab" role="presentation">
            <button
              class="nav-link"
              id="pills-cc-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-cc"
              type="button"
              role="tab"
              aria-selected="false"
            >
              {{
                $t(
                  "BusinessRuleActions.CC",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
          <li class="nav-item bccTab" role="presentation">
            <button
              class="nav-link"
              id="pills-cc-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-bcc"
              type="button"
              role="tab"
              aria-selected="false"
            >
              {{
                $t(
                  "BusinessRuleActions.BCC",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
        </ul>
        <div class="tab-content tabs sendNotification">
          <div
            class="tab-pane fade active show"
            id="pills-to-who"
            role="tabpanel"
          >
            <div class="row row-cols-12 mt-3 ToTab">
              <div class="col col-md-12">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceTypeTO"
                  :data="sourceTypeDataTO"
                  @onChange="onChangeForSourceTypeToWho"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 source">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedOrgUnitTO"
                  :data="organizationalUnitData"
                  @onChange="onChangeForSourceSelectForTo"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 user">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  :selectedData="selectedUserTO"
                  @onMultipleSelectChange="onChangeForUserTO"
                  @onMultipleSelectChangeReturnValues="onChangeForUserTONames"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 userGroup">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  :selectedData="selectedUserGroupTO"
                  @onMultipleSelectChange="onChangeForUserGroupTO"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForUserGroupTONames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 permissionGroup"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :allowEmpty="false"
                  :selectedData="selectedPermissionGroupTO"
                  @onMultipleSelectChange="onChangeForPermissionGroupTO"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForPermissionGroupTONames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 mailToFromField"
              >
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedFieldTO"
                  @onMultipleSelectChange="onChangeForSourceFromFieldTO"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForSourceFromFieldTONames
                  "
                  :isParameters="true"
                  :allowEmpty="false"
                  :resetSelect="selectsReset"
                  :isGatewayRequest="true"
                  :requestUrl="
                    businessRuleActionData.notifierTypeId == '2'
                      ? String.format(
                          '/Lcdp-SummaryGetRelatedPhoneFieldsByCustomObject?parentId={0}',
                          this.$route.params.customObjectId
                        )
                      : String.format(
                          '/Lcdp-SummaryGetRelatedEmailFieldsByCustomObject?parentId={0}',
                          this.$route.params.customObjectId
                        )
                  "
                  openDirection="top"
                />
              </div>
              <div class="col col-md-12 mt-3 mailToType1">
                <label for="Source" class="form-label required"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="RecipientsToText"
                  v-model="businessRuleActionData.recipientsTo"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
            <div
              style="display: none"
              class="row row-cols-12 mt-3 timeTriggeredTab"
            >
              <div class="col col-md-12">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeData4"
                  @onChange="onChangeForSourceTypeToWhoTimeTriggered"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 source6">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedUser"
                  :data="userData"
                  @onChange="onChangeForSourceSelect6"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 user6">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUser"
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 userGroup6">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserGroup"
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 permissionGroup6"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForPermissionGroup"
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                />
              </div>
              <div class="col col-md-12 mt-3 sourceText6">
                <label for="Source" class="form-label required"
                  >{{
                    $t(
                      "BusinessRuleActions.EmailAdressInformation",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Source"
                  v-model="businessRuleActionData.source"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-cc" role="tabpanel">
            <div class="row row-cols-12 mt-3">
              <div class="col col-md-12 otherCc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceTypeCC"
                  :data="sourceTypeDataCCandBCC"
                  @onChange="onChangeForSourceTypeCC"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 timeTriggeredCc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeData5"
                  @onChange="onChangeForSourceTypeTimeTriggeredCc"
                  openDirection="top"
                />
              </div>
              <div class="col col-md-12 mt-3 mailCCType1">
                <label for="source" class="form-label"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="RecipientsCCText"
                  v-model="businessRuleActionData.recipientsCC"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 source2">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedOrgUnitCC"
                  :data="organizationalUnitData"
                  @onChange="onChangeForSourceSelectForCC"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 user2">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserCC"
                  @onMultipleSelectChange="onChangeForUserCC"
                  @onMultipleSelectChangeReturnValues="onChangeForUserCCNames"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 userGroup2">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserGroupCC"
                  @onMultipleSelectChange="onChangeForUserGroupCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForUserGroupCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 permissionGroup2"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedPermissionGroupCC"
                  @onMultipleSelectChange="onChangeForPermissionGroupCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForPermissionGroupCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 mailCcFromField"
              >
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedFieldCC"
                  @onMultipleSelectChange="onChangeForSourceFromFieldCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForSourceFromFieldCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryGetRelatedEmailFieldsByCustomObject?parentId={0}',
                      this.$route.params.customObjectId
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 related2">
                <label for="source" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :isMultiple="true"
                  :selectedData="selectedRelatedUserDataCC"
                  :data="relatedSource"
                  @onChange="onChangeForRelatedCC"
                  openDirection="top"
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-bcc" role="tabpanel">
            <div class="row row-cols-12 mt-3">
              <div class="col col-md-12 otherBcc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceTypeBCC"
                  :data="sourceTypeDataCCandBCC"
                  @onChange="onChangeForSourceTypeBCC"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 timeTriggeredBcc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeData5"
                  @onChange="onChangeForSourceTypeTimeTriggeredBcc"
                  openDirection="top"
                />
              </div>
              <div class="col col-md-12 mt-3 mailBCCType1">
                <label for="source" class="form-label"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="RecipientsBCCText"
                  v-model="businessRuleActionData.recipientsBCC"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 source3">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedOrgUnitBCC"
                  :data="organizationalUnitData"
                  @onChange="onChangeForSourceSelectForBCC"
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 user3">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserBCC"
                  @onMultipleSelectChange="onChangeForUserBCC"
                  @onMultipleSelectChangeReturnValues="onChangeForUserBCCNames"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 userGroup3">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedUserGroupBCC"
                  @onMultipleSelectChange="onChangeForUserGroupBCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForUserGroupBCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 permissionGroup3"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedPermissionGroupBCC"
                  @onMultipleSelectChange="onChangeForPermissionGroupBCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForPermissionGroupBCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                style="display: none"
                class="col col-md-12 mt-3 mailBccFromField"
              >
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  :selectedData="selectedFieldBCC"
                  @onMultipleSelectChange="onChangeForSourceFromFieldBCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForSourceFromFieldBCCNames
                  "
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryGetRelatedEmailFieldsByCustomObject?parentId={0}',
                      this.$route.params.customObjectId,
                      this.fieldsType
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div style="display: none" class="col col-md-12 mt-3 related3">
                <label for="source" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :isMultiple="true"
                  :selectedData="selectedRelatedUserDataBCC"
                  :data="relatedSource"
                  @onMultipleSelectChange="onChangeForRelatedBCC"
                  @onMultipleSelectChangeReturnValues="
                    onChangeForRelatedBCCNames
                  "
                  openDirection="top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectEditBusinessRuleActionSendNotification",
  data() {
    return {
      selectsReset: false,
      notificationTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SMS",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.SystemNotification",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedNotificationType: [],
      selectedNotifierSetting: [],
      selectedMessageTemplate: [],
      selectedNotificationPlugin: [],
      selectedAttachments: [],
      sourceTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.FromTheFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeDataTO: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.FromTheFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeDataCCandBCC: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.FromTheFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRuleActions.Related",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeData3: [
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.FromTheFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRuleActions.Related",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeData4: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeData5: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.Related",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedSourceType: [],
      selectedSourceTypeTO: [],
      selectedSourceTypeCC: [],
      selectedSourceTypeBCC: [],
      selectedSourceType3: [
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      relatedSource: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FirstManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SecondManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.ThirdManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRuleActions.Teammate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      userData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.UserGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.PermissionGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      organizationalUnitData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.UserGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.PermissionGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedUser: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedUserTO: [],
      selectedUserGroupTO: [],
      selectedPermissionGroupTO: [],
      selectedFieldTO: [],
      selectedUserCC: [],
      selectedUserGroupCC: [],
      selectedPermissionGroupCC: [],
      selectedFieldCC: [],
      selectedRelatedUserDataCC: [],
      selectedUserBCC: [],
      selectedUserGroupBCC: [],
      selectedPermissionGroupBCC: [],
      selectedFieldBCC: [],
      selectedRelatedUserDataBCC: [],
      selectedOrgUnitTO: [],
      selectedOrgUnitCC: [],
      selectedOrgUnitBCC: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      createRecordFieldFormulasForEdit: [],
      businessRuleActionData: {},
      model: {},
      selectedCustomObjectCreate: [],
      selectedCustomObjectUpdate: [],
      businessRuleActionType: "",
      businessRuleTriggerTypeId: null,
      notificationType: 1,
      sourceSelect: "User",
      userList: "",
      userGroupList: "",
      permissionGroupList: "",
      fieldsType: "Email|FormulaOrganizationalUnit",
      businessActionObject: [],
      detailForwardOrEditForwardType: false,
      multipleSelect: this.$t(
        "Fields.MultipleSelect",
        {},
        { locale: this.$store.state.activeLang }
      ),
      select: this.$t(
        "Fields.Select",
        {},
        { locale: this.$store.state.activeLang }
      ),
      table: this.$t(
        "Fields.Table",
        {},
        { locale: this.$store.state.activeLang }
      ),
    };
  },
  methods: {
    nameKeyup() {
      if (String.isNullOrWhiteSpace(this.businessRuleActionData.name)) {
        this.businessRuleActionData.formulaName = "";
        return;
      }
      this.businessRuleActionData.formulaName = String.replaceAlphanumeric(
        this.businessRuleActionData.name,
        "_"
      ).toUpperCase();
    },
    formulaNameKeyup() {
      this.businessRuleActionData.formulaName = String.replaceAlphanumeric(
        this.businessRuleActionData.formulaName,
        "_"
      ).toUpperCase();
    },
    onChangeForSourceTypeToWho: function (selected) {
      $(".mailBccFromField").hide();
      $(".related3").hide();
      $(".mailCcFromField").hide();
      $(".related2").hide();
      this.businessRuleActionData.recipientsToInfoType = selected.key;
      if (selected.key == "1") {
        $(".source").hide();
        $(".mailToType1").show();
        $(".user").hide();
        $(".userGroup").hide();
        $(".permissionGroup").hide();
        $(".mailToFromField").hide();
        $(".related").hide();
      } else if (selected.key == "2") {
        $(".source").show();
        $(".user").show();
        $(".mailToType1").hide();
        $(".mailToFromField").hide();
        $(".related").hide();
      } else if (selected.key == "3") {
        $(".source").hide();
        $(".user").hide();
        $(".userGroup").hide();
        $(".permissionGroup").hide();
        $(".mailToType1").hide();
        $(".mailToFromField").show();
        $(".related").hide();
      }
    },
    onChangeForSourceTypeCC: function (selected) {
      this.businessRuleActionData.recipientsCCInfoType = selected.key;
      // $(".mailToFromField").hide();
      // $(".related").hide();
      // $(".mailBccFromField").hide();
      // $(".related3").hide();
      if (selected.key == "1") {
        $(".source2").hide();
        $(".mailCCType1").show();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".mailCcFromField").hide();
        $(".related2").hide();
      } else if (selected.key == "2") {
        $(".source2").show();
        $(".user2").show();
        $(".mailCCType1").hide();
        $(".mailCcFromField").hide();
        $(".related2").hide();
      } else if (selected.key == "3") {
        $(".source2").hide();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".mailCCType1").hide();
        $(".mailCcFromField").show();
        $(".related2").hide();
      } else if (selected.key == "4") {
        $(".source2").hide();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".mailCCType1").hide();
        $(".mailCcFromField").hide();
        $(".related2").show();
      }
    },
    onChangeForSourceTypeTimeTriggeredCc: function (selected) {
      $(".related").hide();
      $(".related3").hide();
      if (selected.key == "1") {
        $(".source2").hide();
        $(".mailCCType1").show();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".related2").hide();
      } else if (selected.key == "2") {
        $(".source2").show();
        $(".user2").show();
        $(".mailCCType1").hide();
        $(".related2").hide();
      } else if (selected.key == "3") {
        $(".source2").hide();
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
        $(".mailCCType1").hide();
        $(".related2").show();
      }
    },
    onChangeForSourceTypeBCC: function (selected) {
      this.businessRuleActionData.recipientsBCCInfoType = selected.key;
      if (selected.key == "1") {
        $(".source3").hide();
        $(".mailBCCType1").show();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".mailBccFromField").hide();
        $(".related3").hide();
      } else if (selected.key == "2") {
        $(".source3").show();
        $(".user3").show();
        $(".mailBCCType1").hide();
        $(".mailBccFromField").hide();
        $(".related3").hide();
      } else if (selected.key == "3") {
        $(".source3").hide();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".mailBCCType1").hide();
        $(".mailBccFromField").show();
        $(".related3").hide();
      } else if (selected.key == "4") {
        $(".source3").hide();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".mailBCCType1").hide();
        $(".mailBccFromField").hide();
        $(".related3").show();
      }
    },
    onChangeForSourceTypeTimeTriggeredBcc: function (selected) {
      if (selected.key == "1") {
        $(".source3").hide();
        $(".mailBCCType1").show();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".related3").hide();
        $(".related").hide();
        $(".related2").hide();
      } else if (selected.key == "2") {
        $(".source3").show();
        $(".user3").show();
        $(".mailBCCType1").hide();
        $(".related3").hide();
        $(".related").hide();
        $(".related2").hide();
      } else if (selected.key == "3") {
        $(".source3").hide();
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
        $(".mailBCCType1").hide();
        $(".related3").show();
        $(".related").hide();
        $(".related2").hide();
      }
    },
    onChangeForSourceTypeToWhoSms: function (selected) {
      $(".mailBccFromField").hide();
      $(".related3").hide();
      $(".mailCcFromField").hide();
      $(".related2").hide();
      $(".mailToFromField").hide();
      $(".fieldsByType5").hide();
      if (selected.key == "1") {
        $(".source4").hide();
        $(".sourceText4").show();
        $(".user4").hide();
        $(".userGroup4").hide();
        $(".permissionGroup4").hide();
        $(".fieldsByType4").hide();
      } else if (selected.key == "2") {
        $(".source4").show();
        $(".user4").show();
        $(".sourceText4").hide();
        $(".fieldsByType4").hide();
      } else if (selected.key == "3") {
        $(".source4").hide();
        $(".user4").hide();
        $(".userGroup4").hide();
        $(".permissionGroup4").hide();
        $(".sourceText4").hide();
        $(".fieldsByType4").show();
      } else if (selected.key == "4") {
        $(".source4").hide();
        $(".user4").hide();
        $(".userGroup4").hide();
        $(".permissionGroup4").hide();
        $(".sourceText4").hide();
        $(".fieldsByType4").hide();
      }
    },
    onChangeForSourceTypeToWhoTimeTriggered: function (selected) {
      $(".related3").hide();
      $(".related2").hide();
      if (selected.key == "1") {
        $(".source6").hide();
        $(".sourceText6").show();
        $(".user6").hide();
        $(".userGroup6").hide();
        $(".permissionGroup6").hide();
      } else if (selected.key == "2") {
        $(".source6").show();
        $(".user6").show();
        $(".sourceText6").hide();
      }
    },
    onChangeForSourceSelectForTo: function (selected) {
      this.businessRuleActionData.recipientsToOrganizationalUnit = selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        $(".user").show();
        $(".userGroup").hide();
        $(".permissionGroup").hide();
        this.businessRuleActionData.recipientsToOrgUnitUser = selected.value;
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        $(".user").hide();
        $(".userGroup").show();
        $(".permissionGroup").hide();

        selected.value;
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        $(".user").hide();
        $(".userGroup").hide();
        $(".permissionGroup").show();
        this.businessRuleActionData.recipientsToOrgUnitPermissionGroup =
          selected.value;
      }
    },
    onChangeForSourceSelectForCC: function (selected) {
      this.businessRuleActionData.recipientsCCOrganizationalUnit = selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        this.businessRuleActionData.recipientsCCOrgUnitUser = selected.value;
        $(".user2").show();
        $(".userGroup2").hide();
        $(".permissionGroup2").hide();
      } else if (selected.key == "2") {
        this.businessRuleActionData.recipientsCCOrgUnitUserGroup =
          selected.value;
        this.sourceSelect = "UserGorup";
        $(".user2").hide();
        $(".userGroup2").show();
        $(".permissionGroup2").hide();
      } else if (selected.key == "3") {
        this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup =
          selected.value;
        this.sourceSelect = "PermissionGroup";
        $(".user2").hide();
        $(".userGroup2").hide();
        $(".permissionGroup2").show();
      }
    },
    onChangeForSourceSelectForBCC: function (selected) {
      this.businessRuleActionData.recipientsBCCOrganizationalUnit =
        selected.key;
      if (selected.key == "1") {
        this.businessRuleActionData.recipientsBCCOrgUnitUser;
        this.sourceSelect = "User";
        $(".user3").show();
        $(".userGroup3").hide();
        $(".permissionGroup3").hide();
      } else if (selected.key == "2") {
        this.businessRuleActionData.recipientsBCCOrgUnitUserGroup;
        this.sourceSelect = "UserGorup";
        $(".user3").hide();
        $(".userGroup3").show();
        $(".permissionGroup3").hide();
      } else if (selected.key == "3") {
        this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup;
        this.sourceSelect = "PermissionGroup";
        $(".user3").hide();
        $(".userGroup3").hide();
        $(".permissionGroup3").show();
      }
    },
    onChangeForSourceSelect4: function (selected) {
      if (selected.key == "1") {
        this.sourceSelect = "User";
        $(".user4").show();
        $(".userGroup4").hide();
        $(".permissionGroup4").hide();
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        $(".user4").hide();
        $(".userGroup4").show();
        $(".permissionGroup4").hide();
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        $(".user4").hide();
        $(".userGroup4").hide();
        $(".permissionGroup4").show();
      }
    },
    onChangeForSourceSelect5: function (selected) {
      if (selected.key == "1") {
        this.sourceSelect = "User";
        $(".user5").show();
        $(".userGroup5").hide();
        $(".permissionGroup5").hide();
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        $(".user5").hide();
        $(".userGroup5").show();
        $(".permissionGroup5").hide();
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        $(".user5").hide();
        $(".userGroup5").hide();
        $(".permissionGroup5").show();
      }
    },
    onChangeForSourceSelect6: function (selected) {
      if (selected.key == "1") {
        this.sourceSelect = "User";
        $(".user6").show();
        $(".userGroup6").hide();
        $(".permissionGroup6").hide();
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        $(".user6").hide();
        $(".userGroup6").show();
        $(".permissionGroup6").hide();
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        $(".user6").hide();
        $(".userGroup6").hide();
        $(".permissionGroup6").show();
      }
    },
    onChangeForUser: function (ids) {
      this.userList = ids;
    },
    onChangeForUserTO: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitUser = ids;
      this.businessRuleActionData.recipientsTo = ids;
    },
    onChangeForUserTONames: function (names) {
      this.businessRuleActionData.recipientsToName = names;
    },
    onChangeForUserCC: function (ids) {
      this.businessRuleActionData.recipientsCCOrgUnitUser = ids;
      this.businessRuleActionData.recipientsCC = ids;
    },
    onChangeForUserCCNames: function (names) {
      this.businessRuleActionData.recipientsCCName = names;
    },
    onChangeForUserBCC: function (ids) {
      this.businessRuleActionData.recipientsBCCOrgUnitUser = ids;
      this.businessRuleActionData.recipientsBCC = ids;
    },
    onChangeForUserBCCNames: function (names) {
      this.businessRuleActionData.recipientsBCCName = names;
    },
    onChangeForUserGroup: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = ids;
    },
    onChangeForUserGroupTO: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = ids;
      this.businessRuleActionData.recipientsTo = ids;
    },
    onChangeForUserGroupTONames: function (names) {
      this.businessRuleActionData.recipientsToName = name;
    },
    onChangeForUserGroupCC: function (ids) {
      this.businessRuleActionData.recipientsCCOrgUnitUserGroup = ids;
      this.businessRuleActionData.recipientsCC = ids;
    },
    onChangeForUserGroupCCNames: function (names) {
      this.businessRuleActionData.recipientsCCName = names;
    },
    onChangeForUserGroupBCC: function (ids) {
      this.businessRuleActionData.recipientsBCCOrgUnitUserGroup = ids;
      this.businessRuleActionData.recipientsBCC = ids;
    },
    onChangeForUserGroupBCCNames: function (names) {
      this.businessRuleActionData.recipientsBCCName = names;
    },
    onChangeForPermissionGroup: function (ids) {
      this.permissionGroupList = ids;
    },
    onChangeForPermissionGroupTO: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitPermissionGroup = ids;
      this.businessRuleActionData.recipientsTo = ids;
    },
    onChangeForPermissionGroupTONames: function (names) {
      this.businessRuleActionData.recipientsToName = name;
    },
    onChangeForPermissionGroupCC: function (ids) {
      this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup = ids;
      this.businessRuleActionData.recipientsCC = ids;
    },
    onChangeForPermissionGroupCCNames: function (names) {
      this.businessRuleActionData.recipientsCCName = name;
    },
    onChangeForPermissionGroupBCC: function (ids) {
      this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup = ids;
      this.businessRuleActionData.recipientsBCC = ids;
    },
    onChangeForPermissionGroupBCCNames: function (names) {
      this.businessRuleActionData.recipientsBCCName = names;
    },
    onChangeForSourceFromFieldTO: function (ids) {
      this.businessRuleActionData.recipientsToFromField = ids;
      this.businessRuleActionData.recipientsTo = ids;
    },
    onChangeForSourceFromFieldTONames: function (names) {
      this.businessRuleActionData.recipientsToName = names;
      this.businessRuleActionData.recipientsToLookUpFieldsNames = names;
    },
    onChangeForSourceFromFieldCC: function (ids) {
      this.businessRuleActionData.recipientsCCFromField = ids;
      this.businessRuleActionData.recipientsCC = ids;
    },
    onChangeForSourceFromFieldCCNames: function (names) {
      this.businessRuleActionData.recipientsCCName = names;
      this.businessRuleActionData.recipientsCCLookUpFieldsNames = names;
    },
    onChangeForSourceFromFieldBCC: function (ids) {
      this.businessRuleActionData.recipientsBCCFromField = ids;
      this.businessRuleActionData.recipientsBCC = ids;
    },
    onChangeForSourceFromFieldBCCNames: function (names) {
      this.businessRuleActionData.recipientsBCCName = names;
      this.businessRuleActionData.recipientsBCCLookUpFieldsNames = names;
    },
    onChangeForRelatedCC: function (selected) {
      this.businessRuleActionData.recipientsCC = selected;
      this.businessRuleActionData.ecipientsCCFromRelationTo = selected;
    },
    onChangeForRelatedCCNames: function (names) {
      this.businessRuleActionData.recipientsCCName = names;
    },
    onChangeForRelatedBCC: function (selected) {
      this.businessRuleActionData.recipientsBCC = selected;
      this.businessRuleActionData.recipientsBCCFromRelationTo = selected;
    },
    onChangeForRelatedBCCNames: function (names) {
      this.businessRuleActionData.recipientsBCCName = names;
    },
    getCustomObjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomObjectGet?id={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.businessActionObject = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onChangeForNotificationType: function (selected, isGetData = false) {
      this.businessRuleActionData.notifierTypeId = selected.key;
      if (!isGetData) {
        this.selectsReset = !this.selectsReset;
      }

      this.notificationType = selected.key;
      if (selected.key == "2") {
        this.fieldsType = "Phone";
        $(".systemNotificationTab").hide();
        $(".email").hide();
        $(".ccTab").hide();
        $(".bccTab").hide();
        $(".sendByEmail").hide();
        $("#pills-to-who-tab").addClass("active");
      } else if (selected.key == "3") {
        this.fieldsType = "OrganizationalUnit|FormulaOrganizationalUnit";
        $(".notificationPlugin").hide();
        $(".attachment").hide();
        $(".ccTab").hide();
        $(".bccTab").hide();
        $(".sendByEmail").hide();
        $("#pills-to-who-tab").addClass("active");
      } else {
        this.fieldsType = "Email|FormulaOrganizationalUnit";
        $(".systemNotificationTab").hide();
        $(".email").show();
        $(".ccTab").show();
        $(".bccTab").show();
        $(".sendByEmail").show();
        $(".notificationPlugin").show();
        $(".attachment").show();
      }
    },
    onChangeForNotifierSettings: function (selected) {
      this.businessRuleActionData.notifierSettingPublicId = selected.key;
      this.businessRuleActionData.notifierSettingName = selected.value;
    },
    onChangeForMessageTemplate: function (selected) {
      this.businessRuleActionData.MessageTemplatePublicId = selected.key;
      this.businessRuleActionData.MessageTemplateName = selected.value;
    },
    onChangeFormTemplatesForBusinessRule: function (selected) {
      this.businessRuleActionData.AttachementFormTemplatePublicIds =
        selected.key;
      this.businessRuleActionData.AttachementFormTemplateNames = selected.value;
    },
    onChangeForFieldsByTypeFile: function (ids) {
      this.businessRuleActionData.AttachmentPublicIds = ids;
    },
    onChangeForFieldsByTypeFileNames: function (names) {
      this.businessRuleActionData.AttachmentNames = names;
    },
    onSubmit(buttonName) {
      var form = $(".form-businessruleactionsendnotif-edit");
      form.addClass("was-validated");
      if (
        !this.$root.brActionValidity(form[0]) ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }
      // if (this.businessRuleActionData.recipientsToInfoType == 1) {
      //   this.businessRuleActionData.recipientsToName =
      //     this.businessRuleActionData.recipientsToText;
      //   this.businessRuleActionData.recipientsTo =
      //     this.businessRuleActionData.recipientsToText;
      // }
      // if (this.businessRuleActionData.recipientsCCInfoType == 1) {
      //   this.businessRuleActionData.recipientsCCName =
      //     this.businessRuleActionData.recipientsCCText;
      //   this.businessRuleActionData.recipientsCC =
      //     this.businessRuleActionData.recipientsCCText;
      // }
      // if (this.businessRuleActionData.recipientsBCCInfoType == 1) {
      //   this.businessRuleActionData.recipientsBCCName =
      //     this.businessRuleActionData.recipientsBCCText;
      //   this.businessRuleActionData.recipientsBCC =
      //     this.businessRuleActionData.recipientsBCCText;
      // }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-BusinessRuleActionUpdateActionSendNotification", {
          ...this.businessRuleActionData,
        })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/BusinessRuleActions/" +
                this.$route.params.customObjectId +
                "&businessRuleId=" +
                this.$route.params.businessRuleId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getBusinessRuleActionData() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-BusinessRuleActionGetAction?id={0}",
            this.$route.params.businessRuleActionId
          )
        )
        .then((response) => {
          this.businessRuleActionData = response.data;

          var selectedNotification = this.notificationTypeData.find(
            (f) => f.key == this.businessRuleActionData.notifierTypeId
          );
          this.onChangeForNotificationType(
            {
              key: this.businessRuleActionData.notifierTypeId,
            },
            true
          );
          // this.businessRuleActionData.notifierTypeId =
          //   this.businessRuleActionData.notifierType.value;
          this.businessRuleActionData.notifierType = null;
          this.selectedNotificationType.push({
            key: selectedNotification.key,
            value: selectedNotification.value,
          });
          this.selectedNotifierSetting.push({
            key: this.businessRuleActionData.notifierSettingPublicId,
            value: this.businessRuleActionData.notifierSettingName,
          });
          this.selectedMessageTemplate.push({
            key: this.businessRuleActionData.messageTemplatePublicId,
            value: this.businessRuleActionData.messageTemplateName,
          });
          if (
            !String.isNullOrWhiteSpace(
              this.businessRuleActionData.attachementFormTemplatePublicIds
            )
          ) {
            var attachementFormTemplatePublicIds =
              this.businessRuleActionData.attachementFormTemplatePublicIds.split(
                this.$systemSeparator
              );
            var attachementFormTemplateNames =
              this.businessRuleActionData.attachementFormTemplateNames.split(
                this.$systemSeparator
              );
            attachementFormTemplatePublicIds.forEach((v, i) => {
              this.selectedNotificationPlugin.push({
                key: v,
                value: attachementFormTemplateNames[i],
              });
            });
          }
          if (
            !String.isNullOrWhiteSpace(
              this.businessRuleActionData.attachmentPublicIds
            )
          ) {
            var attachmentPublicIds =
              this.businessRuleActionData.attachmentPublicIds.split(
                this.$systemSeparator
              );
            var attachmentNames =
              this.businessRuleActionData.attachmentNames.split(
                this.$systemSeparator
              );
            attachmentPublicIds.forEach((v, i) => {
              if (!String.isNullOrWhiteSpace(v)) {
                this.selectedAttachments.push({
                  key: v,
                  value: attachmentNames[i],
                });
              }
            });
          }

          var sourceTypeTo = this.sourceTypeDataTO.find(
              (f) => f.key == this.businessRuleActionData.recipientsToInfoType
            ),
            sourceTypeCC = this.sourceTypeDataCCandBCC.find(
              (f) => f.key == this.businessRuleActionData.recipientsCCInfoType
            ),
            sourceTypeBCC = this.sourceTypeDataCCandBCC.find(
              (f) => f.key == this.businessRuleActionData.recipientsBCCInfoType
            );

          this.selectedSourceTypeTO.push({
            key: sourceTypeTo.key,
            value: sourceTypeTo.value,
          });

          this.selectedSourceTypeCC.push({
            key: sourceTypeCC.key,
            value: sourceTypeCC.value,
          });

          this.selectedSourceTypeBCC.push({
            key: sourceTypeBCC.key,
            value: sourceTypeBCC.value,
          });

          this.onChangeForSourceTypeToWho(sourceTypeTo);
          this.onChangeForSourceTypeCC(sourceTypeCC);
          this.onChangeForSourceTypeBCC(sourceTypeBCC);

          if (
            !String.isNullOrWhiteSpace(
              this.businessRuleActionData.recipientsToName
            ) ||
            !String.isNullOrWhiteSpace(this.businessRuleActionData.recipientsTo)
          ) {
            this.SetRecipientValueTo(
              sourceTypeTo.key,
              this.businessRuleActionData.recipientsToOrganizationalUnit
            );
          }

          if (
            !String.isNullOrWhiteSpace(
              this.businessRuleActionData.recipientsCCName
            ) ||
            !String.isNullOrWhiteSpace(this.businessRuleActionData.recipientsCC)
          ) {
            this.SetRecipientValueCC(
              sourceTypeCC.key,
              this.businessRuleActionData.recipientsCCOrganizationalUnit
            );
          }

          if (
            !String.isNullOrWhiteSpace(
              this.businessRuleActionData.recipientsBCC
            ) ||
            !String.isNullOrWhiteSpace(
              this.businessRuleActionData.recipientsBCCName
            )
          ) {
            this.SetRecipientValueBCC(
              sourceTypeBCC.key,
              this.businessRuleActionData.recipientsBCCOrganizationalUnit
            );
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    SetRecipientValueTo(sourceType, orgUnitType) {
      switch (sourceType) {
        case 1:
          this.businessRuleActionData.recipientsToText =
            this.businessRuleActionData.recipientsTo;
          this.businessRuleActionData.recipientsToName =
            this.businessRuleActionData.recipientsTo;
          break;
        case 2:
          var selectedOrgUnitItemTo = this.organizationalUnitData.find(
            (f) =>
              f.key ==
              this.businessRuleActionData.recipientsToOrganizationalUnit
          );
          this.selectedOrgUnitTO.push({
            key: selectedOrgUnitItemTo.key,
            value: selectedOrgUnitItemTo.value,
          });
          this.onChangeForSourceSelectForTo(selectedOrgUnitItemTo);
          var recipientsToOrgUnit =
            this.businessRuleActionData.recipientsTo.split(
              this.$systemSeparator
            );
          var recipientsToOrgUnitNames =
            this.businessRuleActionData.recipientsToName.split(
              this.$systemSeparator
            );
          if (orgUnitType == 1) {
            recipientsToOrgUnit.forEach((v, i) => {
              this.selectedUserTO.push({
                key: v,
                value: recipientsToOrgUnitNames[i],
              });
            });
            this.onChangeForUserTO(this.businessRuleActionData.recipientsTo);
            this.onChangeForUserTONames(
              this.businessRuleActionData.recipientsToName
            );
          } else if (orgUnitType == 2) {
            recipientsToOrgUnit.forEach((v, i) => {
              this.selectedUserGroupTO.push({
                key: v,
                value: recipientsToOrgUnitNames[i],
              });
            });
            this.onChangeForUserGroupTO(
              this.businessRuleActionData.recipientsTo
            );
            this.onChangeForUserGroupTONames(
              this.businessRuleActionData.recipientsToName
            );
          } else if (orgUnitType == 3) {
            recipientsToOrgUnit.forEach((v, i) => {
              this.selectedPermissionGroupTO.push({
                key: v,
                value: recipientsToOrgUnitNames[i],
              });
            });
            this.onChangeForPermissionGroupTO(
              this.businessRuleActionData.recipientsTo
            );
            this.onChangeForPermissionGroupTONames(
              this.businessRuleActionData.recipientsToName
            );
          }
          break;
        case 3:
          var recipientsToField =
            this.businessRuleActionData.recipientsTo.split(
              this.$systemSeparator
            );
          var recipientsToLookUpFieldsNames =
            this.businessRuleActionData.recipientsToName.split(
              this.$systemSeparator
            );
          recipientsToField.forEach((v, i) => {
            this.selectedFieldTO.push({
              key: v,
              value: recipientsToLookUpFieldsNames[i],
            });
          });
          this.onChangeForSourceFromFieldTO(
            this.businessRuleActionData.recipientsTo
          );
          this.onChangeForSourceFromFieldTONames(
            this.businessRuleActionData.recipientsToLookUpFieldsNames
          );
          break;
        default:
          break;
      }
    },
    SetRecipientValueCC(sourceType, orgUnitType) {
      switch (sourceType) {
        case 1:
          this.businessRuleActionData.recipientsCCText =
            this.businessRuleActionData.recipientsCC;
          this.businessRuleActionData.recipientsCCName =
            this.businessRuleActionData.recipientsCC;
          break;
        case 2:
          var selectedOrgUnitItemCC = this.organizationalUnitData.find(
            (f) =>
              f.key ==
              this.businessRuleActionData.recipientsCCOrganizationalUnit
          );
          this.selectedOrgUnitCC.push({
            key: selectedOrgUnitItemCC.key,
            value: selectedOrgUnitItemCC.value,
          });
          this.onChangeForSourceSelectForCC(selectedOrgUnitItemCC);
          var recipientsCCOrgUnit =
            this.businessRuleActionData.recipientsCC.split(
              this.$systemSeparator
            );
          var recipientsCCOrgUnitNames =
            this.businessRuleActionData.recipientsCCName.split(
              this.$systemSeparator
            );
          if (orgUnitType == 1) {
            recipientsCCOrgUnit.forEach((v, i) => {
              this.selectedUserCC.push({
                key: v,
                value: recipientsCCOrgUnitNames[i],
              });
            });
            this.onChangeForUserCC(this.businessRuleActionData.recipientsCC);
            this.onChangeForUserCCNames(
              this.businessRuleActionData.recipientsCCName
            );
          } else if (orgUnitType == 2) {
            recipientsCCOrgUnit.forEach((v, i) => {
              this.selectedUserGroupCC.push({
                key: v,
                value: recipientsCCOrgUnitNames[i],
              });
            });
            this.onChangeForUserGroupCC(
              this.businessRuleActionData.recipientsCC
            );
            this.onChangeForUserGroupCCNames(
              this.businessRuleActionData.recipientsCCName
            );
          } else if (orgUnitType == 3) {
            recipientsCCOrgUnit.forEach((v, i) => {
              this.selectedPermissionGroupCC.push({
                key: v,
                value: recipientsCCOrgUnitNames[i],
              });
            });
            this.onChangeForPermissionGroupCC(
              this.businessRuleActionData.recipientsCC
            );
            this.onChangeForPermissionGroupCCNames(
              this.businessRuleActionData.recipientsCCName
            );
          }
          break;
        case 3:
          var recipientsCCField =
            this.businessRuleActionData.recipientsCC.split(
              this.$systemSeparator
            );
          var recipientsCCLookUpFieldsNames =
            this.businessRuleActionData.recipientsCCName.split(
              this.$systemSeparator
            );
          recipientsCCField.forEach((v, i) => {
            this.selectedFieldCC.push({
              key: v,
              value: recipientsCCLookUpFieldsNames[i],
            });
          });
          this.onChangeForSourceFromFieldCC(
            this.businessRuleActionData.recipientsCC
          );
          this.onChangeForSourceFromFieldCCNames(
            this.businessRuleActionData.recipientsCCName
          );
          break;
        case 4:
          var recipientsCCRelated =
            this.businessRuleActionData.recipientsCC.split(
              this.$systemSeparator
            );
          var recipientsCCRelatedNames =
            this.businessRuleActionData.recipientsCCName.split(
              this.$systemSeparator
            );
          recipientsCCRelated.forEach((v, i) => {
            this.selectedRelatedUserDataCC.push({
              key: v,
              value: recipientsCCRelatedNames[i],
            });
          });
          this.onChangeForRelatedCC(this.businessRuleActionData.recipientsCC);
          this.onChangeForRelatedCCNames(
            this.businessRuleActionData.recipientsCCName
          );
          break;

        default:
          break;
      }
    },
    SetRecipientValueBCC(sourceType, orgUnitType) {
      switch (sourceType) {
        case 1:
          this.businessRuleActionData.recipientsBCCText =
            this.businessRuleActionData.recipientsBCC;
          this.businessRuleActionData.recipientsBCCName =
            this.businessRuleActionData.recipientsBCC;
          break;
        case 2:
          var selectedOrgUnitItemBCC = this.organizationalUnitData.find(
            (f) =>
              f.key ==
              this.businessRuleActionData.recipientsBCCOrganizationalUnit
          );
          this.selectedOrgUnitBCC.push({
            key: selectedOrgUnitItemBCC.key,
            value: selectedOrgUnitItemBCC.value,
          });
          this.onChangeForSourceSelectForBCC(selectedOrgUnitItemBCC);
          var recipientsBCCOrgUnit =
            this.businessRuleActionData.recipientsBCC.split(
              this.$systemSeparator
            );
          var recipientsBCCOrgUnitNames =
            this.businessRuleActionData.recipientsBCCName.split(
              this.$systemSeparator
            );
          if (orgUnitType == 1) {
            recipientsBCCOrgUnit.forEach((v, i) => {
              this.selectedUserBCC.push({
                key: v,
                value: recipientsBCCOrgUnitNames[i],
              });
            });
            this.onChangeForUserBCC(this.businessRuleActionData.recipientsBCC);
            this.onChangeForUserBCCNames(
              this.businessRuleActionData.recipientsBCCName
            );
          } else if (orgUnitType == 2) {
            recipientsBCCOrgUnit.forEach((v, i) => {
              this.selectedUserGroupBCC.push({
                key: v,
                value: recipientsBCCOrgUnitNames[i],
              });
            });
            this.onChangeForUserGroupBCC(
              this.businessRuleActionData.recipientsBCC
            );
            this.onChangeForUserGroupBCCNames(
              this.businessRuleActionData.recipientsBCCName
            );
          } else if (orgUnitType == 3) {
            recipientsBCCOrgUnit.forEach((v, i) => {
              this.selectedPermissionGroupBCC.push({
                key: v,
                value: recipientsBCCOrgUnitNames[i],
              });
            });
            this.onChangeForPermissionGroupBCC(
              this.businessRuleActionData.recipientsBCC
            );
            this.onChangeForPermissionGroupBCCNames(
              this.businessRuleActionData.recipientsBCCName
            );
          }
          break;
        case 3:
          var recipientsBCCField =
            this.businessRuleActionData.recipientsBCC.split(
              this.$systemSeparator
            );
          var recipientsBCCLookUpFieldsNames =
            this.businessRuleActionData.recipientsBCCName.split(
              this.$systemSeparator
            );
          recipientsBCCField.forEach((v, i) => {
            this.selectedFieldBCC.push({
              key: v,
              value: recipientsBCCLookUpFieldsNames[i],
            });
          });
          this.onChangeForSourceFromFieldBCC(
            this.businessRuleActionData.recipientsBCC
          );
          this.onChangeForSourceFromFieldBCCNames(
            this.businessRuleActionData.recipientsBCCLookUpFieldsNames
          );
          break;
        case 4:
          var recipientsBCCRelated =
            this.businessRuleActionData.recipientsBCC.split(
              this.$systemSeparator
            );
          var recipientsBCCRelatedNames =
            this.businessRuleActionData.recipientsBCCName.split(
              this.$systemSeparator
            );
          recipientsBCCRelated.forEach((v, i) => {
            this.selectedRelatedUserDataBCC.push({
              key: v,
              value: recipientsBCCRelatedNames[i],
            });
          });
          this.onChangeForRelatedBCC(this.businessRuleActionData.recipientsBCC);
          this.onChangeForRelatedBCCNames(
            this.businessRuleActionData.recipientsBCCName
          );
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.getCustomObjectDetail();
    this.getBusinessRuleActionData();
    this.businessRuleTriggerTypeId = localStorage.getItem(
      "businessRuleTriggerTypeId"
    );
    this.businessRuleActionType = localStorage.getItem(
      "businessRuleActionType"
    );
  },
};
</script>
